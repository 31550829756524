<template>
  <div class="brand-content" v-loading="formLoading">
    <el-form
      size="medium"
      :model="brandForm"
      :rules="rules"
      label-position="right"
      label-width="100px"
      class="small-form"
      ref="brandForm"
      @submit.native.prevent
    >
      <div class="modular">
        <p class="modular-title">基础信息</p>
        <div class="modular-content" style="margin-left: 11px">
          <el-form-item label="内容名称：" prop="name">
            <el-input
              type="text"
              v-model="brandForm.name"
              placeholder="请输入内容名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="简介类型：" prop="description_type" required>
            <el-radio-group v-model="brandForm.description_type">
              <el-radio :label="0">纯文本</el-radio>
              <el-radio :label="1">富文本</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="简介信息：" prop="description">
            <template v-if="brandForm.description_type">
              <tinymce
                ref="editor"
                @choose="handleToolBarClick"
                v-model="brandForm.description"
                show-article-import
              ></tinymce>
            </template>
            <el-input
              v-else
              type="textarea"
              :rows="10"
              :maxlength="3000"
              show-word-limit
              v-model="brandForm.description"
              placeholder="请输入简介信息"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="分类：" prop="category_id">
            <div class="flex">
              <el-select
                v-model="brandForm.category_id"
                :placeholder="optionsLoading ? '分类加载中...' : '请选择分类'"
              >
                <el-option
                  v-for="item in categoryOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <AddCategoryButton
                :style="{
                  marginLeft: '12px',
                }"
                :updateList="getCategoryOptions"
                :getApi="getApi"
              ></AddCategoryButton>
            </div>
          </el-form-item>
          <el-form-item label="自定义标题1：" prop="custom_title_host">
            <el-input
              v-model="brandForm.custom_title_host"
              placeholder="请输入自定义标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="hosts">
            <draggable
              style="width: 70%"
              v-model="brandForm.hosts"
              :sort="true"
              forceFallback="true"
              handle=".tag-item"
              group="view_index"
              animation="300"
            >
              <transition-group class="tag">
                <div
                  class="tag-item"
                  v-for="(tag, index) in brandForm.hosts"
                  :key="tag.user_id"
                >
                  <el-tag v-if="tag" closable @close="handleClose(index)">
                    {{ `${tag.nickname} ( ${tag.title_name} )` }}
                  </el-tag>
                </div>
                <el-button
                  v-if="brandForm.hosts.length < 8"
                  size="small"
                  class="add-hosts"
                  type="primary"
                  @click="openSelectMember = true"
                  :key="-1"
                  >+ 添加</el-button
                >
              </transition-group>
            </draggable>
            <div
              class="form-tip"
              :style="brandForm.hosts.length ? 'margin-top: 0px' : ''"
            >
              <span
                class="el-icon-info"
                style="font-size: 15px; color: #c0c4cc"
              ></span>
              <span>最多添加 8 个会员数据，点击拖动调整排序</span>
            </div>
          </el-form-item>
          <el-form-item label="自定义标题2：" prop="custom_title_guest">
            <el-input
              v-model="brandForm.custom_title_guest"
              placeholder="请输入自定义标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="guest_config">
            <el-button type="primary" @click="addGuest">添加数据</el-button>
            <div class="form-tip">
              <span
                class="el-icon-info"
                style="font-size: 15px; color: #c0c4cc"
              ></span>
              <span
                >添加用户名称、用户简介，最多添加 8
                个数据，点击拖动调整排序</span
              >
            </div>
            <draggable
              class="guest-list"
              v-if="brandForm.guest_config.length"
              v-model="brandForm.guest_config"
              :sort="true"
              forceFallback="true"
              handle=".list-item"
              group="view_index"
              animation="300"
            >
              <transition-group>
                <div
                  class="list-item"
                  v-for="(item, index) in brandForm.guest_config"
                  :key="item.uid"
                >
                  <el-image
                    class="item-avatar"
                    :src="item.avatar"
                    fit="cover"
                  ></el-image>
                  <div class="item-name">{{ item.name }}</div>
                  <div class="item-introduct">{{ item.introduct }}</div>
                  <div style="margin-left: 39px">
                    <el-button type="text" @click="editGuest(item, index)"
                      >编辑</el-button
                    >
                    <el-button type="text" @click="delGuest(index)"
                      >删除</el-button
                    >
                  </div>
                </div>
              </transition-group>
            </draggable>
          </el-form-item>
          <el-form-item label="运营收藏数：" prop="virtual_collect">
            <el-input
              type="number"
              v-model.number="brandForm.virtual_collect"
              placeholder="请输入运营收藏数"
            >
            </el-input>
            <div class="form-tip">
              <span
                class="el-icon-info"
                style="font-size: 15px; color: #c0c4cc"
              ></span>
              <span>运营收藏数 + 会员收藏数 = 总收藏数</span>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">运营图</p>
        <div class="modular-content" style="margin-left: 11px">
          <el-form-item label="运营图片：" prop="images">
            <el-form
              class="input-imgs small-form"
              size="medium"
              :model="brandForm.images"
              :rules="imageRules"
              ref="images"
              @submit.native.prevent
            >
              <el-form-item
                class="input-item media-input-item"
                prop="page_cover"
              >
                <image-wall
                  v-model="brandForm.images.page_cover"
                  :width="194"
                  :height="135"
                  :ratio="[
                    {
                      desc: '模板一（卡片，一行两个）尺寸328*184',
                      ratio: '328*184',
                    },
                    { desc: '模板二（横向滚动）尺寸286*160', ratio: '286*160' },
                    {
                      desc: '模板三（竖向列表，图片在左侧）尺寸284*160',
                      ratio: '284*160',
                    },
                    {
                      desc: '模板四（大图，轮播）尺寸670*376',
                      ratio: '670*376',
                    },
                    {
                      desc: '模板五（竖向列表，大图）尺寸686*378',
                      ratio: '686*378',
                    },
                    {
                      desc: '模板六（竖向列表，大图）尺寸686*446',
                      ratio: '686*446',
                    },
                  ]"
                >
                  <p slot="info" class="info-title">列表封面</p>
                  <!--                  <p slot="info" class="img-tips">尺寸推荐：</p>-->
                  <div
                    slot="info"
                    class="img-tips"
                    style="
                      text-align: left;
                      white-space: nowrap;
                      max-width: 194px;
                    "
                  >
                    <p>模板一（卡片，一行两个）尺寸328*184；</p>
                    <p>模板二（横向滚动）尺寸286*160；</p>
                    <p>模板三（竖向列表，图片在左侧）尺寸284*160；</p>
                    <p>模板四（大图，轮播）尺寸670*376；</p>
                    <p>模板五（竖向列表，大图）尺寸686*378；</p>
                    <p>模板六（竖向列表，大图）尺寸686*446；</p>
                  </div>
                </image-wall>
                <el-input
                  v-show="false"
                  v-model="brandForm.images.page_cover"
                ></el-input>
              </el-form-item>
              <el-form-item
                class="input-item media-input-item"
                prop="detail_cover"
              >
                <image-wall
                  v-model="brandForm.images.detail_cover"
                  :width="194"
                  :height="135"
                  ratio="750:524"
                >
                  <p slot="info" class="info-title">详情头部</p>
                  <p slot="info" class="img-tips">建议上传尺寸比例为750*524</p>
                </image-wall>
                <el-input
                  v-show="false"
                  v-model="brandForm.images.detail_cover"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">页面分享</p>
        <div class="modular-content" style="margin-left: 11px">
          <el-form-item label="封面图：" prop="site_share">
            <div class="input-item">
              <image-wall
                v-model="brandForm.site_share"
                :width="194"
                :height="135"
                ratio="5:4"
              >
                <p slot="info" class="img-tips" style="margin-top: 8px">
                  <span class="el-icon-info"></span
                  >建议上传尺寸比例为5:4,不上传图片时，默认使用系统自带图片
                </p>
              </image-wall>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <guest-detail-dialog
      v-model="openAddGuestDialog"
      :currentIndex="current_guest_index"
      :form="current_guest_from"
      @change="handleGuestChange"
    />
    <SelectMember
      v-model="openSelectMember"
      :limit="8 - brandForm.hosts.length"
      :Members="brandForm.hosts"
      @changeSelect="handleSelect"
    />

    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />
    <FixedActionBar>
      <el-button
        type="primary"
        size="medium"
        @click="formSubmit('brandForm')"
        :loading="saveLoading"
        >保存
      </el-button>
      <el-button size="medium" @click="cancelConfig">取消</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
// import hostCard from "../components/HostCard.vue";
import { brandDetail, brandSave } from '../api/add-brand'
import { categoryOptions } from '../api/brand-category'
import ImageWall from '../../common/components/SingleMediaWall.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import SelectMember from '@/modules/common/components/MemberSelector'
import draggable from 'vuedraggable'
import GuestDetailDialog from '../components/GuestDetailDialog.vue'
import AddCategoryButton from '@/base/components/Base/AddCategoryButton.vue'
import { saveCategory } from '../api/brand-category'

// 富文本相关组件
import Tinymce from '@/base/components/Editor/Tinymce'

import MediaSelector from '@/modules/common/components/MediaSelector.vue'
import ImportArticle from '@/base/components/Editor/ImportArticle'
import ImportHtmlCode from '@/base/components/Editor/ImportHtmlCode'

export default {
  components: {
    draggable,
    ImageWall,
    SelectMember,
    FixedActionBar,
    GuestDetailDialog,
    AddCategoryButton,
    Tinymce,
    MediaSelector,
    ImportArticle,
    ImportHtmlCode,
  },
  data() {
    return {
      brandForm: {
        id: this.$route.params.id,
        name: '',
        description: '',
        description_type: 0,
        category_id: '',
        custom_title_host: '', //
        hosts: [],
        custom_title_guest: '', //
        guest_config: [],
        virtual_collect: 0,
        images: {
          detail_cover: '',
          page_cover: '',
        },
        site_share: '',
      },
      rules: {
        name: [{ required: true, message: '请输入内容名称', trigger: 'blur' }],
        description: [
          { required: true, message: '请输入简介信息', trigger: 'blur' },
        ],
        category_id: [
          { required: true, message: '请选择分类', trigger: 'change' },
        ],
        virtual_collect: [
          { required: true, message: '请输入运营收藏数', trigger: 'blur' },
          { type: 'integer', min: 0, message: '请输入正整数', trigger: 'blur' },
        ],
        images: [
          {
            required: true,
            validator: this.passImages,
            trigger: 'change',
          },
        ],
      },
      imageRules: {
        detail_cover: [
          { required: true, message: '请上传详情头部', trigger: 'change' },
        ],
        page_cover: [
          { required: true, message: '请上传首页封面', trigger: 'change' },
        ],
      },
      current_guest_index: -1,
      current_guest_from: {
        avatar: '',
        name: '',
        introduct: '',
        uid: 0,
      },
      categoryOptions: [],
      optionsLoading: false,
      formLoading: false,
      openSelectImg: false,
      imgSelectType: '', //当前选中图片属性
      saveLoading: false,
      openSelectMember: false,
      openAddGuestDialog: false,

      // 媒体库图片弹窗
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      isShowHtmlInput: false,
    }
  },
  methods: {
    // 富文本点击事件
    handleToolBarClick(e) {
      switch (e.type) {
        case 'image':
          this.showImageList = true
          break
        case 'video':
          this.showVideoList = true
          break
        case 'audio':
          this.showAudioList = true
          break
        case 'import':
          this.isShowImportDialog = true
          break
        case 'html':
          this.isShowHtmlInput = true
          break
      }
    },
    // ------富文本相关
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<img src="${item.url}"/>`
        )
      })
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
          // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        )
      })
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        )
      })
    },
    // 导入公众号文章内容
    getImportDetail(e) {
      // e: {account, content, cover_image, title}

      const newContent = this.form.description + '<p><br/></p>' + e.content
      this.$set(this.form, 'description', newContent)
      this.$refs.editor.$emit('change', newContent)

      if (!this.form.name) this.form.name = e.title
      if (!this.form.cover) this.form.cover = e.cover_image
      if (this.form.images.length < 9) {
        this.form.images.push(e.cover_image)
      }
    },
    // 添加分类通用
    getApi(data) {
      return saveCategory(data)
    },
    // 获取分类数据选项
    getCategoryOptions() {
      this.optionsLoading = true
      categoryOptions()
        .then((res) => {
          this.categoryOptions = res.data
          this.optionsLoading = false
        })
        .catch((err) => {
          this.optionsLoading = false
        })
    },
    // 验证运营图片上传
    passImages(rule, value, callback) {
      if (value) {
        this.$refs.images.validate((valid) => {
          if (valid) {
            callback()
          } else {
            this.$message.error('运营图为必填项')
            this.saveLoading = false
          }
        })
      } else {
        callback()
      }
    },
    // 删除选中
    handleClose(i) {
      this.brandForm.hosts.splice(i, 1)
    },
    //添加数据
    addGuest() {
      if (this.brandForm.guest_config.length < 8) {
        this.current_guest_index = -1
        this.current_guest_from = {
          avatar: '',
          name: '',
          introduct: '',
          uid: new Date().getTime(),
        }
        this.openAddGuestDialog = true
      } else {
        this.$message.error('最多添加 8 个数据')
      }
    },
    // 编辑数据
    editGuest(row, i) {
      this.current_guest_index = i
      this.current_guest_from = row
      this.openAddGuestDialog = true
    },
    // 删除数据
    delGuest(i) {
      this.$msgbox
        .confirm('确定要删除该数据吗？', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.brandForm.guest_config.splice(i, 1)
        })
        .catch((err) => {})
    },
    // 嘉宾数据处理
    handleGuestChange(val) {
      if (this.current_guest_index === -1) {
        this.brandForm.guest_config.push(val)
      } else {
        this.brandForm.guest_config[this.current_guest_index] = val
      }
    },
    // 获取选择会员
    handleSelect(e) {
      this.brandForm.hosts = e
    },
    // 获取品牌详情
    getBrandDetail() {
      this.formLoading = true
      brandDetail({ id: this.brandForm.id })
        .then((res) => {
          const { data } = res
          this.brandForm = data
          this.formLoading = false
        })
        .catch((err) => {
          this.formLoading = false
        })
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          const data = { ...this.brandForm }
          this.saveLoading = true
          data.hosts = JSON.stringify(data.hosts)
          data.images = JSON.stringify(data.images)
          brandSave(data)
            .then((res) => {
              this.$message.success(res.msg)
              if (this.$route.name === 'addBrand') {
                this.$router.push({ name: 'CycBrandList' })
              } else {
                this.getBrandDetail()
              }
              this.saveLoading = false
            })
            .catch((err) => {
              this.saveLoading = false
            })
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error')
            isError[0].scrollIntoView({ block: 'center', behavior: 'smooth' })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 取消
    cancelConfig() {
      this.$router.push({ name: 'CycBrandList' })
    },
  },
  created() {
    this.getCategoryOptions()
    if (this.brandForm.id !== '0') {
      this.getBrandDetail()
    }
  },
}
</script>

<style lang="scss" scoped>
.brand-content {
  min-height: 520px;

  .add-host:hover {
    border-color: #409eff;
  }

  .input-imgs {
    display: flex;
  }

  .footer-btn {
    @include fixed-card-bottom();
    padding-left: 250px;
  }

  .input-item {
    width: 194px;
    text-align: center;
    margin-right: 100px;

    .images {
      width: 194px;
      height: 135px;
      cursor: pointer;
    }

    .upload-btn {
      width: 194px;
      height: 135px;
      border: 1px dashed #d9d9d9;
      .plus-icon {
        font-size: 20px;
        .upload-text {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }

    .info-title {
      font-size: 14px;
      line-height: 30px;
    }

    .img-tips {
      font-size: 12px;
      color: #c0c4cc;
      line-height: 15px;
    }
    .upload-btn:hover {
      border-color: #409eff;
    }
  }
}
.tag {
  display: flex;
  flex-wrap: wrap;
  .tag-item {
    flex-shrink: 0;
    margin-right: 12px;
    margin-bottom: 12px;
    cursor: pointer;
  }
  .add-hosts {
    flex-shrink: 0;
    margin-top: 2px;
    height: 32px;
    padding: 0 22px;
  }
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
.guest-list {
  width: 604px;
  padding: 24px 32px;
  background: #f7f8fa;
  border-radius: 8px;
  margin-top: 20px;
  .list-item + .list-item {
    margin-top: 24px;
  }
  .list-item {
    display: flex;
    align-items: center;
    cursor: move;
    .el-button + .el-button {
      margin-left: 12px;
    }
    .item-avatar {
      flex-shrink: 0;
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }
    .item-name {
      flex-shrink: 0;
      width: 93px;
      margin-left: 17px;
      font-size: 14px;
      color: #3a3a3a;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .item-introduct {
      flex-shrink: 0;
      width: 238px;
      margin-left: 17px;
      font-size: 14px;
      color: #3a3a3a;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
