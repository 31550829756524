<template>
  <div>
    <el-dialog
      :title="currentIndex === -1 ? '添加信息' : '编辑信息'"
      :visible.sync="openDialog"
      width="450px"
      class="dialog-vertical"
      @close="cancel('editFormRef')"
    >
      <el-form
        size="medium"
        :model="editForm"
        :rules="rules"
        label-position="right"
        label-width="90px"
        class="small-form"
        ref="editFormRef"
      >
        <el-form-item v-if="currentIndex === -1" label="用户类型：">
          <el-radio-group v-model="type">
            <el-radio :label="1">会员</el-radio>
            <el-radio :label="0">游客</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="currentIndex !== -1 || !type">
          <el-form-item label="嘉宾头像：" prop="avatar">
            <SingleMediaWall
              v-model="editForm.avatar"
              :width="80"
              :height="80"
              borderRadius="50%"
              :showMask="false"
              addText="上传头像"
            >
            </SingleMediaWall>
            <el-input v-show="false" v-model="editForm.avatar"></el-input>
          </el-form-item>
          <el-form-item label="嘉宾名称：" prop="name">
            <el-input
              v-model="editForm.name"
              placeholder="请输入嘉宾名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="嘉宾简介：" prop="introduct">
            <el-input
              type="textarea"
              resize="none"
              :rows="6"
              :maxlength="95"
              show-word-limit
              v-model="editForm.introduct"
              placeholder="请输入嘉宾简介"
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item v-else label="选择会员：" prop="type">
          <el-button type="plain" @click="handleSelectMember()"
            >请选择</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="openDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="submit('editFormRef')"
          >保存</el-button
        >
      </span>
      <!-- 选择会员弹窗 -->
    </el-dialog>
    <SelectMember
      v-model="openSelectMember"
      :selectType="0"
      selected-type="check"
      :currentIndex="currentIndex"
      @changeSelect="handleSelect"
    />
  </div>
</template>

<script>
import SelectMember from '@/modules/common/components/MemberSelector'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    currentIndex: {
      type: Number,
      default: -1,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  components: { SingleMediaWall, SelectMember },
  data() {
    return {
      editForm: {
        avatar: '',
        name: '',
        introduct: '',
        uid: 0,
      },
      openSelectMember: false,
      type: 1,
      rules: {
        type: [{ required: true, message: '请选择会员', trigger: 'change' }],
        avatar: [
          { required: true, message: '请上传嘉宾头像', trigger: 'change' },
        ],
        name: [{ required: true, message: '请输入嘉宾名称', trigger: 'blur' }],
        introduct: [
          { required: true, message: '请输入嘉宾简介', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    openDialog(val) {
      if (val) {
        if (this.currentIndex !== -1) {
          this.$nextTick(() => {
            this.editForm = { ...this.form }
          })
        } else {
          this.$nextTick(() => {
            this.editForm.uid = this.form.uid
          })
        }
      }
    },
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('change', { ...this.editForm })
          this.openDialog = false
        } else {
          return false
        }
      })
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
      this.$refs[formName].resetFields()
      this.editForm = { avatar: '', name: '', introduct: '', uid: 0 }
    },
    handleSelectMember() {
      this.openSelectMember = true
    },
    handleSelect(e) {
      e = e[0]
      this.$emit('change', {
        avatar: e.avatar,
        name: e.nickname,
        introduct: e.title_name,
        uid: this.editForm.uid,
      })
      this.openDialog = false
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
}
</script>

<style></style>
